const translations   = {
    "az":{
      home:"ANA SƏHİFƏ",
      collections:"KOLLEKSİYALAR",
      blogs:"BLOQLAR",
      about:"HAQQIMIZDA",
      contact:"BİZİMLƏ ƏLAQƏ",
      cart:"Səbət",
      showMore:"DAHA ÇOX",
      empty:"Sizin səbətiniz boşdur",
      total:"Ümumi",
      checkout:"SİFARİŞ",
      luxury:"İlin ən yaxşı lüks telefonları",
      added:"Səbətə əlavə et",
      color:"Rəng",
      quantity:"Miqdar",
      contactDesc:"İstənilən sorğu və ya dəstək üçün bizimlə əlaqə saxlayın. Biz sizə kömək etmək üçün buradayıq!",
      address:"Ünvan",
      district:"rayon",
      street:" küçəsi",
      working:"İş saatları",
      monday:"Bazar ertəsi - Bazar",
      am:"10:00 - 22:00",
      xorAz:"XOR Azərbaycan",
      aboutdesc1:"XOR telefonları lüks və texnologiyanın mükəmməl birləşməsini təklif edir. Titan və sapfir kristal kimi üstün materiallardan əl işi olan hər bir məhdud buraxılışlı telefon zərifliyi Knight's Knot loqosu ilə simvollaşdırılan qabaqcıl məxfilik xüsusiyyətləri ilə birləşdirir. Erqonomik dizaynı və fərdiləşdirmə seçimləri ilə XOR telefonları innovasiya və rəqəmsal dünyadan uzaqlaşma arasında qüsursuz istifadəçi təcrübəsi təklif edir.",
      aboutdesc2:'"MyGroup Holdinq" MMC Azərbaycanda XOR telefonlarının rəsmi reselleri olmaqdan qürur duyur. Mükəmməl məhsul və xidmətlər təklif etmək öhdəliyi ilə tanınan "MyGroup Holdinq" MMC, müştərilərinə lüks brendlərin diqqətlə seçilmiş kolleksiyasını təklif edir. Telefonunuzu alarkən orijinallığa və üstün xidmətə əmin ola bilərsiniz. Yalnız "MyGroup Holdinq" MMC vasitəsilə təklif olunan XOR ilə lüks və müasir texnologiyanın birləşməsini kəşf edin.',
      sortby:"Sırala",
      sort_name:"Ad",
      sort_price:"Qiymət",
      price_low:"Aşağı",
      price_high:"Yuxarı",
      result:"nəticədən 1-8 arası göstərilir",
      previous:"Əvvəlki",
      next:"Sonrakı",
      share:"Paylaş",
      desc:"TƏSVİR",
      details:"XÜSUSİYYƏTLƏRİ",
      copyRight:"Bütün hüquqlar qorunur.",
      yourEmail:"Sizin e-poçt ünvanınız",
      join:"Xəbərlərə abunə olun",
      pages:"Səhifələr",
      cat:"Kateqoriya",
      billing:"Ödəniş Təfərrüatları",
      invalid:"Zəhmət olmasa xananı doldurun",
      readMore:"Daha ətraflı",
      blog_title:"Azərbaycanda XOR mağazasının açılışı",
      blogdesc:"İyulun 24-də Azərbaycanın paytaxtı Bakıda mobil texnologiya sahəsində böyük hadisə baş verdi: XOR markalı telefonlar üçün flaqman mağazasının açılışı.",
      blogdesc1:"İyulun 24-də Azərbaycanın paytaxtı Bakıda mobil texnologiya sahəsində böyük hadisə baş verdi: XOR markalı telefonlar üçün flaqman mağazasının açılışı. Bu təqdimat məşhur MyGroup holdinqi ilə innovativ Britaniya brendi XOR arasında uğurlu əməkdaşlıq nəticəsində mümkün olub. Strateji cəhətdən Bakının mərkəzində yerləşən yeni mağaza XOR-un beynəlxalq arenada iştirakını genişləndirmək üçün mühüm addımdır.",
      blogtitle1:"İnnovasiyalar və Texnologiyalar",
      blogdesc2:"XOR brendi mobil texnologiyalar sahəsində innovativ inkişafları və unikal həlləri sayəsində tanınıb. XOR telefonlarının əsas xüsusiyyətlərindən biri aparat şifrələmə moduludur ki, bu da öz effektivliyinə görə proqram təminatının şifrələnməsini xeyli üstələyir. Aparat şifrələməsi birbaşa uzaq serverlərdən istifadə etmədən həyata keçirilir və şifrələmə açarlarını potensial təhlükələr üçün əlçatmaz edir.",
      blogtitle2:"Niyə XOR??",
      blogdesc3:"XOR telefonlarının əsas xüsusiyyətlərindən biri də başdan sona şifrələmədən istifadə edərək zəng və mesaj mübadiləsi imkanıdır. Bu, unikal rabitə sistemi və etibarlı birdəfəlik parollar dəsti sayəsində əldə edilir. Bu xüsusiyyət, xüsusən də informasiya təhlükəsizliyinin getdikcə daha çox əhəmiyyət kəsb etdiyi müasir dünyada istifadəçilərin şəxsi məlumatlarının ən yüksək səviyyədə qorunmasını təmin edir.",
      blogtitle3:"Açılışın əhəmiyyəti",
      blogdesc4:"Bakıda XOR mağazasının açılması brendin qlobal ambisiyalarını vurğulamaqla yanaşı, şirkətin müştərilərini ən son mobil texnologiya həlləri ilə təmin etmək öhdəliyini nümayiş etdirir. Bu yaxınlarda Ağ Şəhərdə Vertu mağazasını açan MyGroup indi Azərbaycan istehlakçılarına yüksək texnologiyalar və məlumatların qorunması sahəsində unikal imkanlar təklif edir.",
      blogtitle4:"Nəticə",
      blogdesc5:"Bakıdakı XOR flaqman mağazası sadəcə telefon almaq üçün yeni yer deyil, mobil təhlükəsizlik sahəsində yeni eranın başlanğıcıdır. Qabaqcıl texnologiya və innovasiya istəyinin birləşməsi XOR-u bazar lideri edir, istifadəçilərinə rəqibsiz səviyyədə qorunma və rahatlıq təmin edir. Gələcəkdə brendin mövcudluğunu genişləndirməyə və rəqəmsal təhlükəsizliyi təmin etmək üçün daha da təkmil həllər təklif etməyə davam edəcəyini gözləyə bilərik",
      blogdesc6:"Ünvan: Bakı şəhəri, İzmir küçəsi, Hyatt Regency ilə üzbəüz",
      name:"Ad",
      last:"Soyad",
      phone:"Telefon nömrəniz",
      email:"E-poçt",
      radio:"Çatdırılma üsulu :",
      radio1:"Mağazadan əldə etmək",
      radio2:"Ünvana çatdırılma",
      address1:"Yaşadığınız ünvanı daxil edin",
      orderNote:"Sifariş notu",
      orderDesc:"Sifarişiniz haqqında qeydlər, məs. çatdırılma üçün xüsusi qeydlər.",
      orders:"Sifarişiniz",
      product:"Məhsul",
      orderTotal:"Sifariş Cəmi",
      placeOrder:"Sifarişi təsdiqləyin",
      alert:"Sifarişiniz qeydə alındı.",
      ordermsg:"Səbətinizdə hələki məhsul yoxdur",
      collections_f:"Kolleksiyalar",
      payment:"Ödəmə üsulunu seçin :",
      paymentDoor:"Qapıda ödəmə üsulu",
      paymentOnline:"Onlayn Ödəmə üsulu",
      paymentTitle:"Sifariş təfərrüatları :",
      orderMsg:"Bizi seçdiyiniz üçün təşəkkür edirik! Qısa zaman ərzində sizinlə əlaqə saxlanılanacaq.",
      orderTitle:"Məhsul adı",
      orderQuantity:"Miqdar",
      payment1:"Ödəniş",
      paymentmethod:"Çatdırılma üsulu :",
      cash:"Nağd ödəniş",
      onlinePayment:"Onlayn ödəniş",
      search:"Axtarış",
      notresult:"Nəticə tapılmadı."
    },
    "en":{
      home:"HOME",
      collections:"COLLECTIONS",
      blogs:"BLOGS",
      about:"ABOUT",
      contact:"CONTACT US",
      cart:"Cart",
      showMore:"SHOW MORE",
      empty:"Your cart is empty",
      total:"Total",
      checkout:"CHECKOUT",
      luxury:"Top Luxury Phones of the Year",
      added:"Add to cart",
      color:"Color",
      quantity:"Quantity",
      contactDesc:"Get in touch with us for any inquiries or support. We’re here to assist you!",
      address:"Address",
       district:"district",
       street:" street",
       working:"Working hours",
       monday:"Monday - Sunday",
       am:"10AM - 22PM",
       xorAz:"XOR Azerbaijan",
       aboutdesc1:"XOR phones offer a perfect blend of luxury and technology. Handcrafted from premium materials like titanium and sapphire crystal, each limited-edition handset combines elegance with advanced privacy features, symbolized by the Shield Knot logo. With ergonomic design and customization options, XOR phones provide a seamless user experience that balances innovation with an escape from the over-digitized world.",
       aboutdesc2:'"MyGroup Holding" LLC is proud to be the official reseller of XOR phones in the Azerbaijan. Known for its commitment to delivering premium products and services, "MyGroup Holding" LLC offers a curated selection of luxury brands to discerning customers. With MyGroup, you can be assured of authenticity and exceptional service when purchasing your XOR phone. Discover the perfect fusion of luxury and cutting-edge technology with XOR, available exclusively through "MyGroup Holding" LLC',
       sortby:"Sort By",
       sort_name:"Name",
       sort_price:"Price",
       price_low:"Low",
       price_high:"High",
       result:"Results 1-8 are displayed",
       previous:"Previous",
       next:"Next",
       share:"Share",
       desc:"DESCRİPTİON",
       details:"CHARACTERISTICS",
       copyRight:"All Rights Reserved.",
       yourEmail:"Your email address",
       join:"Subscribe to news",
       pages:"Pages",
       cat:"Category",
       billing:"Billing Details",
       invalid:"Please fill in the entry",
       readMore:"Read more",
       blog_title:"Opening of XOR Store in Azerbaijan",
       blogdesc:"On July 24, a significant event in mobile technology took place in Baku, the capital of Azerbaijan: the opening of the flagship store for XOR brand phones.",
       blogdesc1:"On July 24, a significant event in mobile technology took place in Baku, the capital of Azerbaijan: the opening of the flagship store for XOR brand phones. This event was made possible through the successful collaboration between the renowned MyGroup holding and the innovative British brand XOR. Strategically located in the center of Baku, the new store represents an important step in expanding XOR's presence on the international stage.",
       blogtitle1:"Innovations and Technologies",
       blogdesc2:"The XOR brand is known for its innovative developments and unique solutions in mobile technology. One of the key features of XOR phones is the hardware encryption module, which far surpasses software encryption in terms of effectiveness. This hardware encryption is performed directly on the device without using remote servers, making encryption keys inaccessible to potential threats.",
       blogtitle2:"Why XOR?",
       blogdesc3:"A standout feature of XOR phones is their ability to exchange calls and messages using end-to-end encryption. This is achieved through a unique communication system and a set of secure one-time passwords. This feature ensures the highest level of protection for users' personal data, especially in today's world, where information security is becoming increasingly important.",
       blogtitle3:"Importance of the Opening",
       blogdesc4:"The opening of the XOR store in Baku underscores the brand's global ambitions and demonstrates the company's commitment to providing customers with the latest mobile technology solutions. MyGroup, which recently opened a Vertu store in White City, now offers Azerbaijani consumers unique opportunities in high technology and data protection.",
       blogtitle4:"Conclusion",
       blogdesc5:"The XOR flagship store in Baku is more than just a new place to buy a phone; it marks the beginning of a new era in mobile security. The combination of advanced technology and a commitment to innovation makes XOR a market leader, providing users with an unrivaled level of protection and comfort. In the future, we can expect the brand to continue expanding its presence and offering even more advanced solutions for ensuring digital security.",
       blogdesc6:"Address: Baku City, Izmir Street, opposite Hyatt Regency",
       name:"First name",
       last:"Last name",
       phone:"Phone",
       email:"E-mail",
       radio:"Delivery method :",
       radio1:"Get it from the store",
       radio2:"Delivery to the address",
       address1:"Enter your residential address",
       orederNote:"Order note",
       orderDesc:"Notes about your order, e.g. special notes for delivery.",
       orders:"Your order",
       product:"Product",
       orderTotal:"Order Total",
       placeOrder:"Confirm the order",
       alert:"Your order has been registered.",
       ordermsg:"There are no products in your cart yet",
       collections_f:"Collections",
       payment:"Choose a payment method :",
       paymentDoor:"Payment method at the door",
       paymentOnline:"Online Payment method",
       paymentTitle:"Order details:",
       orderMsg:"Thank you for choosing us! You will be contacted shortly.",
       orderTitle:"Product name",
       orderQuantity:"Quantity",
       payment1:"Payment",
       paymentmethod:"Delivery method :",
       orderNote:"Order note",
       cash:"Cash payment",
       onlinePayment:"Online payment",
       search:"Search",
       notresult:"No results found."
    }
}


export default translations ;